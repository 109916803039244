import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import ContentBuilder from '@theme/components/utils/ContentBuilder'

export default {
  components: {
    AppImage,
    AppTitle,
    ContentBuilder,
  },
  props: {
    block: Object,
  },
  computed: {
    getContent() {
      return this.block?.content || {}
    },
    getContentBlocks() {
      return this.block?.content_blocks || []
    },
  },
}
